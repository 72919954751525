.gpw {
    padding: 20px;

    .trigger {
        padding: 5px;
        background: rgb(255, 255, 255);
        border-radius: 1px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px;
        display: inline-block;
        cursor: pointer;

        .inner {
            width: 36px;
            height: 14px;
            border-radius: 2px;
        }
    }

    .popover {
        z-index: 2;
        margin-top: 6px;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px,
            rgba(0, 0, 0, 0.15) 0 8px 16px;
        background-color: white;
        padding: 12px;
        border-radius: 4px;
        position: absolute;
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .controls-wrapper {
        margin: 0 -10px 10px;
        padding: 6px 0 0 10px;
        display: flex;
        flex-wrap: wrap;
        position: relative;

        &.with-angle {
            justify-content: space-around;
            align-items: center;
        }
    }
}

