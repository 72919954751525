.gp {
    display: flex;
    flex-direction: column;
    align-items: center;

    .gp-flat {
        margin: 0 auto;
        padding: 10px 0 0!important;
        box-shadow: none!important;
        transform: none!important;
    }
}
